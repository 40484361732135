html, body{
    margin: 0;
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding: 0 0 800px 0;
}

p{
    font-weight: 350;
    font-style: normal;
    font-size: 15pt;
    letter-spacing: .01em;
    line-height: 1.8em;
    text-transform: none;
    color: #222;
    -webkit-font-smoothing: subpixel-antialiased;
}

.banner{
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-image: url('../public/img/banner-img.jpg');
    display: flex;
    justify-content: center;
}

.banner #carrot{
    align-self: flex-end;
    cursor: pointer;
}

.banner #carrot img{
    width: 125px;
    height: 80px;
    padding-bottom: 20px;
    position: relative;
    animation: jump 3s infinite;
}

.banner #carrot :hover{
    top: 10px;
}

@keyframes jump {
    0%   { bottom: 0px; }
    5%  { bottom: 7px; }
    98%  { bottom: 7px; }
    100%  { bottom: 0px; }
}

.blurb{
    text-align: center;
    white-space: pre-wrap;
    padding: 100px 0;
}

.blurb p{
    max-width: 800px;
    padding: 0 50px;
    margin: 0 auto;
}

.subscribe{
    color: white;
    min-height: 500px;
    overflow: hidden;
    background-size: cover;
    background-position: center; 
    background-image: url('../public/img/subscribe-img.jpg');
    padding-bottom: 65px;
}

.subscribe h1{
    font-weight: 400;
    font-size: 48px;
    padding: 20px;
}

.subscribe a{
    border: 2px solid white;
    border-radius: 2px;
    padding: 15px 0px;
    display: block;
    width: 176px;
    color: white;
    text-decoration: none;
    margin: 0 auto;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
}

.subscribe a:hover{
    color: #1d1d1d;
    background: white;
    transition: 0.4s;
}

.listen{
    padding-bottom: 100px;
}

.listen h1{
    font-weight: 400;
    font-size: 48px;
    padding: 20px;
}

.audioPlayer{
    max-width: 775px;
    margin: 40px auto;
}

.contact{
    background-color: #1d1d1d;
    margin: 0;
    padding: 0px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact p{
    color: white;
    padding: 0 50px;
    margin: 0;
    line-height: 2.5em;
    font-weight: 350;
    font-size: 19px;
}

.socials{
    background-color: #232323;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 190px;
}

.socials a{
    color: white;
    padding: 0 0px;
}

